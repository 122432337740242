<template>
  <div class="row bg-info text-light text-center no-gutters">
    <div class="col">
      Impersonating {{$store.state.auth.impersonatingUser}}.
      <button
        class="btn btn-link text-white"
        @click="stopImpersonating"
      >Stop Impersonating</button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ImpersonateHeader',
  methods: {
    stopImpersonating() {
      const vm = this;
      vm.$store.commit('setImpersonatingUser', null);
      vm.$store.dispatch('getUser', false);
    },
  },
};
</script>

const confirm = {
  methods: {
    showConfirm(messageText, okFunction = null, cancelFunction = null, okText = 'OK', cancelText = 'Cancel') {
      const vm = this;
      const app = vm.$root.$children[0];
      const modal = app.$refs.confirmModal;
      app.confirmModal.text = messageText;
      app.confirmModal.okText = okText;
      app.confirmModal.cancelText = cancelText;
      if (okFunction !== null) {
        app.confirmModal.okFunction = okFunction;
      } else {
        app.confirmModal.okFunction = () => {};
      }
      if (cancelFunction !== null) {
        app.confirmModal.cancelFunction = cancelFunction;
      } else {
        app.confirmModal.cancelFunction = () => {};
      }
      vm.$root.confirmModalVisible = true;
    },
  },
};
export default confirm;

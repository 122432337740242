const alphabetizeByKey = (key, a, b) => {
  let aVal;
  let bVal;
  if (typeof key === 'string') {
    aVal = a[key];
    bVal = b[key];
    if (typeof aVal === 'string') {
      aVal = aVal.toLowerCase();
    }
    if (typeof bVal === 'string') {
      bVal = bVal.toLowerCase();
    }
    if (aVal < bVal) return -1;
    if (aVal > bVal) return 1;
  } else if (key.desc) {
    if (a[key.key].toLowerCase() > b[key.key].toLowerCase()) return -1;
    if (a[key.key].toLowerCase() < b[key.key].toLowerCase()) return 1;
  } else {
    if (a[key.key].toLowerCase() < b[key.key].toLowerCase()) return -1;
    if (a[key.key].toLowerCase() > b[key.key].toLowerCase()) return 1;
  }
  return 0;
};

export default alphabetizeByKey;

/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import dayjs from 'dayjs';
import endpoints from '../../api/endpoints';

// initial state
const state = {
  all: [],
  filtered: [],
  refreshedAt: null,
  saveStatus: null,
  user: {
    applications: [],
    email: '',
    users: [],
    data_mappings: {},
  },
  permissions: [],
  dataProfiles: [],
};

// getters
const getters = {};

const options = {
  endpoint: 'users',
};


// actions
const actions = {

  setRoleUsers(st, params) {
    const vm = this;
    const url = `applications/${params.applicationId}/environments/${params.environment}/${params.roleName}/users`;
    st.commit('progress/setProcessing', url, { root: true });
    return vm.$http.put(url, params.data).then((response) => {
      const users = response.data;
      st.commit('progress/removeProcessing', url, { root: true });
      st.commit('setFilteredUsers', users);
      return users;
    },
    () => {
      st.commit('progress/setError', 'Error saving the user', { root: true });
      st.commit('progress/removeProcessing', url, { root: true });
    });
  },

  getFiltered(st, params) {
    const requestOptions = { ...options };
    requestOptions.parameters = [];
    if (params) {
      if (params.roleName) {
        requestOptions.parameters.push({
          name: 'roles',
          value: params.roleName,
        });
      }
      if (params.environment) {
        requestOptions.parameters.push({
          name: 'environment',
          value: params.environment,
        });
      }
    }
    st.commit('progress/setProcessing', 'users/listFiltered', { root: true });
    requestOptions.endpoint = `applications/${params.applicationId}/users`;
    const users = [];
    const getUsers = (offset) => {
      if (requestOptions.parameters.find(x => x.name === 'offset')) {
        requestOptions.parameters.find(x => x.name === 'offset').value = offset;
      } else {
        requestOptions.parameters.push({
          name: 'offset',
          value: offset,
        });
      }
      return endpoints.list(requestOptions, st).then((response) => {
        users.push(...response.data.map(x => ({ email: x.email })));
        if (response.data.length === 5000) {
          getUsers(offset + 5000);
        }
        st.commit('progress/removeProcessing', 'users/listFiltered', { root: true });
        st.commit('setFilteredUsers', users);
        return users;
      },
      () => {
        st.commit('progress/removeProcessing', 'users/listFiltered', { root: true });
      });
    };
    return getUsers(0);
  },

  getAll(st) {
    const vm = this;
    const refreshedMoment = dayjs(st.state.refreshedAt);
    if (!st.state.refreshedAt || refreshedMoment.add(3, 'minutes').isBefore(dayjs())) {
      st.commit('progress/setProcessing', 'users/list', { root: true });
      return vm.$http.get('users?fields=email,updated_at').then((response) => {
        const users = response.data;
        st.commit('progress/removeProcessing', 'users/list', { root: true });
        st.commit('setUsers', users);
        return users;
      },
      () => {
        st.commit('progress/removeProcessing', 'users/list', { root: true });
      });
    }
    return new Promise(resolve => resolve(st.state.all));
  },

  get(st, id) {
    if (!st.state.user || st.state.user.email.toLowerCase() !== id.toLowerCase()) {
      return st.dispatch('getFromAPI', id).then((user) => {
        st.commit('setUser', user);
        return user;
      },
      () => {
        st.commit('setSaveStatus', 'failed');
        st.commit('progress/removeProcessing', 'users/get', { root: true });
      });
    }
    return new Promise(resolve => resolve(st.state.user));
  },

  getFromAPI(st, id) {
    st.commit('progress/setProcessing', 'users/get', { root: true });
    options.id = id;
    return endpoints.get(options, st).then((response) => {
      const user = response.data;
      st.commit('progress/removeProcessing', 'users/get', { root: true });
      return user;
    },
    () => {
      st.commit('setSaveStatus', 'failed');
      st.commit('progress/removeProcessing', 'users/get', { root: true });
    });
  },

  getWithAllEnvironments(st, id) {
    st.commit('progress/setProcessing', 'users/get', { root: true });
    const apiOptions = { ...options };
    apiOptions.id = id;
    apiOptions.parameters = [{ name: 'environment', value: 'all' }];
    return endpoints.get(apiOptions, st).then((response) => {
      const user = response.data;
      st.commit('setUser', user);
      st.commit('progress/removeProcessing', 'users/get', { root: true });
      return user;
    },
    () => {
      st.commit('setSaveStatus', 'failed');
      st.commit('progress/removeProcessing', 'users/get', { root: true });
    });
  },

  getAppPermissions(st, data) {
    st.commit('progress/setProcessing', 'AppPermissions/get', { root: true });
    const apiOptions = { ...options };
    apiOptions.path = `users/${data.email}/applications/${data.application}`;
    if (data.environment) {
      apiOptions.parameters = [{ name: 'environment', value: data.environment }];
    }
    return endpoints.get(apiOptions, st).then((response) => {
      const responseData = response.data;
      st.commit('setPermissions', responseData.permissions);
      st.commit('progress/removeProcessing', 'AppPermissions/get', { root: true });
      return responseData.permissions;
    },
    () => {
      st.commit('setSaveStatus', 'failed');
      st.commit('progress/removeProcessing', 'AppPermissions/get', { root: true });
    });
  },

  getDataProfiles(st, data) {
    st.commit('progress/setProcessing', 'DataProfiles/get', { root: true });
    const apiOptions = { ...options };
    apiOptions.path = `users/${data.email}/applications/${data.application}/dataprofiles`;
    apiOptions.parameters = [
      {
        name: 'environment',
        value: data.environment,
      },
    ];
    return endpoints.get(apiOptions, st).then((response) => {
      const dataProfiles = response.data;
      st.commit('setDataProfiles', dataProfiles);
      st.commit('progress/removeProcessing', 'DataProfiles/get', { root: true });
      return dataProfiles;
    },
    () => {
      st.commit('setSaveStatus', 'failed');
      st.commit('progress/removeProcessing', 'DataProfiles/get', { root: true });
    });
  },

  save(st, data) {
    st.commit('setSaveStatus', null);
    st.commit('progress/setProcessing', 'users/save', { root: true });
    options.data = data;
    return endpoints.create(options, st).then((response) => {
      st.commit('setSaveStatus', 'successful');
      st.commit('setUser', response.data);
      st.commit('progress/removeProcessing', 'users/save', { root: true });
      st.commit('progress/setSuccess', 'User has been successfully created', { root: true });
      st.commit('setRefreshed', null);
      return response.data;
    },
    () => {
      st.commit('setSaveStatus', 'failed');
      st.commit('progress/removeProcessing', 'users/save', { root: true });
      st.commit('progress/setError', 'Error saving the user', { root: true });
    });
  },

  update(st, data) {
    st.commit('setSaveStatus', null);
    st.commit('progress/setProcessing', 'users/update', { root: true });
    options.data = data;
    options.itemPath = data.email;
    return endpoints.update(options, st).then((response) => {
      st.commit('setSaveStatus', 'successful');
      st.commit('setUser', response.data);
      st.commit('progress/removeProcessing', 'users/update', { root: true });
      st.commit('progress/setSuccess', 'User has been successfully updated', { root: true });
      st.commit('setRefreshed', null);
      return response.data;
    },
    () => {
      st.commit('setSaveStatus', 'failed');
      st.commit('progress/removeProcessing', 'users/update', { root: true });
      st.commit('progress/setError', 'Error saving the user', { root: true });
    });
  },

  updateApplications(st, data) {
    st.commit('setSaveStatus', null);
    st.commit('progress/setProcessing', 'users/update', { root: true });
    options.data = data.applications;
    options.itemPath = `${data.email}/applications`;
    return endpoints.update(options, st).then((response) => {
      st.commit('setSaveStatus', 'successful');
      st.commit('progress/removeProcessing', 'users/update', { root: true });
      st.commit('progress/setSuccess', 'User has been successfully updated', { root: true });
      st.commit('setRefreshed', null);
      return response.data;
    },
    () => {
      st.commit('setSaveStatus', 'failed');
      st.commit('progress/removeProcessing', 'users/update', { root: true });
      st.commit('progress/setError', 'Error saving the user', { root: true });
    });
  },

  updateMappings(st, data) {
    st.commit('setSaveStatus', null);
    st.commit('progress/setProcessing', 'users/update', { root: true });
    options.data = data.data_mappings;
    options.itemPath = `${data.email}/datamappings`;
    return endpoints.update(options, st).then((response) => {
      st.commit('setSaveStatus', 'successful');
      st.commit('progress/removeProcessing', 'users/update', { root: true });
      st.commit('progress/setSuccess', 'User has been successfully updated', { root: true });
      st.commit('setRefreshed', null);
      return response.data;
    },
    () => {
      st.commit('setSaveStatus', 'failed');
      st.commit('progress/removeProcessing', 'users/update', { root: true });
      st.commit('progress/setError', 'Error saving the user', { root: true });
    });
  },

  setSaveStatus({ commit }, status) {
    commit('setSaveStatus', status);
  },

  addUserToList({ commit }, data) {
    commit('addUserToList', data);
    commit('setRefreshed', null);
  },

  removeUserFromList(st, data) {
    st.commit('progress/setProcessing', 'users/removeUserFromList', { root: true });
    const apiOptions = { ...options };
    apiOptions.data = data;
    return endpoints.update(apiOptions, st).then((response) => {
      const users = response.data;
      st.commit('setUsers', users);
      st.commit('progress/removeProcessing', 'users/removeUserFromList', { root: true });
      return users;
    }, () => {
      st.commit('setSaveStatus', 'failed');
      st.commit('progress/removeProcessing', 'users/removeUserFromList', { root: true });
      st.commit('progress/setError', 'Error deleting the user', { root: true });
    });
  },

  updateUserWithoutSave({ commit }, data) {
    commit('setUser', data);
    commit('setRefreshed', null);
  },

};

// mutations
const mutations = {
  setUsers(st, users) {
    // eslint-disable-next-line no-param-reassign
    st.all = users;
    st.refreshedAt = new Date();
  },

  setRefreshed(st, refreshed) {
    // eslint-disable-next-line no-param-reassign
    st.refreshedAt = refreshed;
  },

  setFilteredUsers(st, users) {
    // eslint-disable-next-line no-param-reassign
    st.filtered = users;
  },

  setUser(st, user) {
    // eslint-disable-next-line no-param-reassign
    st.user = user;
  },

  setPermissions(st, permissions) {
    // eslint-disable-next-line no-param-reassign
    st.permissions = permissions;
  },

  setDataProfiles(st, dataProfiles) {
    // eslint-disable-next-line no-param-reassign
    st.dataProfiles = dataProfiles;
  },

  setSaveStatus(st, status) {
    // eslint-disable-next-line no-param-reassign
    st.saveStatus = status;
  },

  addUserToList(st, user) {
    // eslint-disable-next-line no-param-reassign
    st.all.push(user);
  },

  removeUserFromList(st, user) {
    // eslint-disable-next-line no-param-reassign
    const index = st.all.findIndex(x => x.email === user.email);
    st.all.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div>
    <page-header :title="title">
      <template v-slot:header-controls v-if="hasHeaderControls">
        <slot name="header-controls"></slot>
      </template>
    </page-header>
    <div class="row no-gutters">
      <div class="col-12 gray-bg px-1 px-md-5">
        <slot></slot>
      </div>
      <div class="col-12 mt-4">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    hasHeaderControls() {
      return !!this.$slots['header-controls'];
    },
  },
  props: {
    title: {
      type: String,
      required: false,
    },
  },
};
</script>

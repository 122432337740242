/* eslint-disable no-param-reassign */
// initial state
const state = {
  errorMessage: null,
  successMessage: null,
  loadingModules: [],
};

// getters
const getters = {};


// actions
const actions = {
  setErrorMessage({ commit }, message) {
    commit('setError', message);
  },

  setSuccessMessage({ commit }, message) {
    commit('setSuccess', message);
  },

  setProcessing({ commit }, moduleName) {
    commit('setProcessing', moduleName);
  },
};

// mutations
const mutations = {
  setError(st, message) {
    // eslint-disable-next-line no-param-reassign
    st.errorMessage = message;
  },

  setSuccess(st, message) {
    // eslint-disable-next-line no-param-reassign
    st.successMessage = message;
  },

  setProcessing(st, moduleName) {
    if (st.loadingModules.indexOf(moduleName) === -1) {
      // eslint-disable-next-line no-param-reassign
      st.loadingModules.push(moduleName);
    }
  },

  removeProcessing(st, moduleName) {
    if (st.loadingModules && st.loadingModules.length) {
      st.loadingModules.splice(st.loadingModules.indexOf(moduleName), 1);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import Vue from 'vue';
import alphabetizeByKey from '@/utilities/alphabetizeByKey';

const baseURL = process.env.VUE_APP_MDM_API;

const buildRecordsURL = (entityName, filter, filterByRecord, page, count, detailed) => {
  const offset = (page - 1) * count;
  if (detailed === undefined) {
    detailed = true;
  }
  let url = `${baseURL}/entities/${entityName}/records?include_temporary=true&count=${count}&offset=${offset}&detailed=${detailed}`;
  if (filter) {
    url = `${url}&name=${filter}`;
  } else {
    url = `${url}&order_by=name`;
  }
  if (filterByRecord) {
    let filterString = filterByRecord;
    if (Array.isArray(filterString)) {
      filterString = filterString.join();
    }
    url = `${url}active_records=${filterString}`;
  }
  return url;
};

export default {
  namespaced: true,
  state: {
    Portal: {
      all: [],
    },
    'Client Teams': {
      all: [],
    },
    Channels: {
      all: [],
    },
    Partners: {
      all: [],
    },
    Dayparts: {
      all: [],
    },
    'Execution Types': {
      all: [],
    },
    'Buy Types': {
      all: [],
    },
    'Rate Types': {
      all: [],
    },
    'Partner Parent Company': {
      all: [],
    },
    Agencies: {
      all: [],
    },
    Entities: [],
  },
  getters: {},
  actions: {
    getEntityNames({ state, commit }) {
      const vm = this;
      if (!state.Entities || !state.Entities.length) {
        return vm.$http.get(`${baseURL}/entities`).then((data) => {
          if (data.data) {
            commit('setEntityNames', data.data.items.map(x => ({
              name: x.name,
              id: x.id,
              description: x.description,
            })));
          }
          commit('progress/removeProcessing', 'list entity names', { root: true });
          return data;
        }, (error) => {
          commit('progress/removeProcessing', 'list entity names', { root: true });
          return error;
        });
      }
      return Promise.resolve(state.Entities);
    },

    listEntities({ state }, entities) {
      for (let i = 0; i < entities.length; i += 1) {
        state.dispatch('listEntity', entities[i]);
      }
    },

    listEntity({
      state, commit, dispatch,
    }, entity) {
      if (state[entity.name] && state[entity.name].all.length) {
        return Promise.resolve(state[entity.name].all);
      }
      const vm = this;
      const url = buildRecordsURL(
        entity.name,
        entity.filter,
        entity.filterByRecord,
        1,
        1000,
        entity.detailed,
      );
      const invalidateCache = (
        state[entity.name]
          && state[entity.name].invalidateCache
      );
      const request = {};
      if (invalidateCache) {
        request.headers = { 'Cache-Control': 'max-age=0' };
      }
      commit('resetEntityRecords', entity.name);
      commit('progress/setProcessing', url, { root: true });
      const getData = () => dispatch('getTotalCount', entity).then((total) => {
        const pages = Math.ceil(total / 1000);
        const promises = [];
        for (let i = 1; i <= pages; i += 1) {
          const pageUrl = buildRecordsURL(
            entity.name,
            entity.filter,
            entity.filterByRecord,
            i,
            1000,
            entity.detailed,
          );
          promises.push(vm.$http.get(pageUrl, request));
        }
        return Promise.all(promises).then((responses) => {
          const records = responses.map(x => x.data.items).flat(1);
          commit('setEntityData', { entity: entity.name, data: records });
          commit('progress/removeProcessing', url, { root: true });
          return records;
        });
      });
      if (state.Entities.length) {
        return getData();
      }
      return dispatch('getEntityNames').then(() => getData());
    },

    getTotalCount({ state }, entity) {
      const vm = this;
      const url = `${buildRecordsURL(entity.name, entity.filter, entity.filterByRecord, 1, 0)}&detailed=false`;
      const invalidateCache = (
        state[entity.name]
          && state[entity.name].invalidateCache
      );
      const request = {};
      if (invalidateCache) {
        request.headers = { 'Cache-Control': 'max-age=0' };
      }
      return vm.$http.get(url, request).then(response => response.data.total, error => error);
    },

  },
  mutations: {
    resetEntityRecords(state, entity) {
      if (!state.Entities[entity]) {
        state[entity] = {};
      }
      state[entity].all = [];
      state[entity].filtered = [];
    },

    setEntityData(state, payload) {
      if (!state[payload.entity]) {
        state[payload.entity] = {};
      }
      // eslint-disable-next-line no-param-reassign
      state[payload.entity].all = payload.data.sort(alphabetizeByKey.bind(null, 'name'));
      state[payload.entity].refreshedAt = new Date();
    },

    setEntityNames(state, payload) {
      state.Entities = payload.sort(alphabetizeByKey.bind(null, 'name'));
    },
  },
};

import { createApp, configureCompat } from 'vue';
import Popper from 'vue3-popper';
import '@omg-technology-shared-resources/omg-technology-styles';
import '@omg-technology-shared-resources/omg-technology-styles/dist/omg-technology.css';
import http from '@/api/http';
import IconButton from '@/components/IconButton';
import Accordion from '@/components/Accordion';
import AccordionPanel from '@/components/AccordionPanel';
import BasicLayout from '@/layouts/BasicLayout';
import PageHeader from '@/layouts/PageHeader';
import TableContainer from '@/layouts/TableContainer';
import store from './store';
import App from './App';
import router from './router';

configureCompat({
  COMPONENT_V_MODEL: false,
  WATCH_ARRAY: false,
  ATTR_FALSE_VALUE: false,
});

const app = createApp(App).use(store);
app.config.globalProperties.$http = http;
app.config.globalProperties.$store = store;
store.$http = http;
app.use(router);
app.use(Popper);
app.component('accordion', Accordion);
app.component('accordion-panel', AccordionPanel);
app.component('table-container', TableContainer);
app.component('page-header', PageHeader);
app.component('basic-layout', BasicLayout);
app.component('icon-button', IconButton);
app.mount('#app');

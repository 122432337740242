<template>
  <div id="impersonate-wrapper">
    <Popper arrow>
      <button
        id="user-popover"
        class="btn btn-link px-0"
      ><i class="fas fa-mask" id="impersonate-icon"></i></button>
      <template #content>
        <floating-label-input
          v-model="selectedUser"
          inputType="text"
          class="impersonate-selector"
          label="Impersonate User"
          :autocomplete="users.map(x => ({ id: x.email, name: x.email }))"
          @update:modelValue="impersonate"
          id="impersonate-input"
        />
      </template>
    </Popper>
  </div>
</template>
<style lang="scss">
.bg-dark .floating-label-input.impersonate-selector .floating-label {
  color: #404040;
}
.impersonate-selector {
  width: 250px;
}
.btn-link, .btn-link:hover {
  #impersonate-icon {
    color: #fff;
  }
}
.impersonate-selector.floating-label-input.has-focus .floating-label,
.impersonate-selector.floating-label-input.value-filled .floating-label {
  color: #fff;
}
</style>
<style lang="scss" scoped>
  #impersonate-wrapper {
    position: relative;
    display: inline-block;
  }
</style>
<script>
import { mapState } from 'vuex';

export default {
  name: 'ImpersonateSelector',
  data() {
    return {
      selectedUser: null,
    };
  },
  computed: {
    ...mapState({
      users: state => state.users.all,
    }),
  },
  methods: {
    impersonate() {
      const vm = this;
      vm.$store.commit('setImpersonatingUser', vm.selectedUser);
      vm.$store.dispatch('getUser', false);
    },
  },
  created() {
    const vm = this;
    vm.$store.dispatch('users/getAll');
  },
};
</script>

<template>
  <div>
    <div class="row align-items-center" v-if="hasHeaderControls">
      <div class="col my-4">
        <slot name="header-controls"></slot>
      </div>
    </div>
    <div class="row align-items-end" v-else>
      <div class="col-12 my-4 py-2">
        <h1 class="mb-0" v-if="title">{{title}}</h1>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    hasHeaderControls() {
      return !(Object.keys(this.$slots).length === 0 && this.$slots.constructor === Object);
    },
  },
  props: {
    title: {
      type: String,
      required: false,
    },
  },
};
</script>

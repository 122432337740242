import Vue from 'vue';
import Vuex from 'vuex';
import appClients from './modules/appclients';
import applications from './modules/applications';
import users from './modules/users';
import permissions from './modules/permissions';
import mdm from './modules/mdm';
import progress from './modules/progress';
import auth from './modules/auth';
import mainmenu from './modules/mainmenu';

Vue.use(Vuex);
localStorage.removeItem('mdm');
localStorage.removeItem('platform-x-security-default');
export default new Vuex.Store({
  modules: {
    appClients,
    applications,
    users,
    permissions,
    progress,
    auth,
    mdm,
    mainmenu,
  },
});

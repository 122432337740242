/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import router from '@/router';
import endpoints from '@/api/auth';
import capitalize from '@/utilities/capitalize';
import jwt from 'jwt-decode';
import dayjs from 'dayjs';

const options = {
  endpoint: 'auth',
  data: {},
};

const defaultState = {
  profile: null,
  accessToken: null,
  verification: null,
  isAuthenticated: false,
  isInitializing: false,
  count: 0,
  requestedRoute: null,
  expires: null,
  loggedInUser: {},
  refreshedAt: null,
  redirectUri: null,
  refreshToken: null,
  authorizationCode: null,
  authorizationState: null,
  user: null,
  impersonatingUser: null,
};
let localState = window.localStorage.getItem('protego-auth');

// Force re-authentication if user is not authenticated with current IDP
if (localState) {
  const parsed = JSON.parse(window.localStorage.getItem('protego-auth'));
  if (
    parsed.profile
    && parsed.profile.identities
    && parsed.profile.identities.length
    && parsed.profile.identities[0].providerName !== process.env.VUE_APP_AWS_COGNITO_IDP
  ) {
    localState = null;
  }
}

if (!localState) {
  window.localStorage.setItem('protego-auth', JSON.stringify(defaultState));
  localState = window.localStorage.getItem('protego-auth');
}

export default {
  state: JSON.parse(window.localStorage.getItem('protego-auth')),

  getters: {
    profile: state => state.profile,
    verification: state => state.verification,
    accessToken: state => state.accessToken,
    refreshToken: state => state.refreshToken,
    idToken: state => state.idToken,
    isAuthenticated: state => state.isAuthenticated,
    managesAnApp: (state) => {
      if (state.user && state.user.applications) {
        for (let i = 0; i < state.user.applications.length; i += 1) {
          const application = state.user.applications[i];
          if (application.environments) {
            for (let x = 0; x < application.environments.length; x += 1) {
              const environment = application.environments[x];
              const managerRoleName = process.env.VUE_APP_MAGIC_ROLE_NAME;
              const accessManagerRole = environment.roles.find(role => (
                role.name.toLowerCase() === managerRoleName.toLowerCase()
              ));
              const roleManagerRoleName = process.env.VUE_APP_SECOND_MAGIC_ROLE_NAME;
              const roleManagerRole = environment.roles.find(role => (
                role.name.toLowerCase() === roleManagerRoleName.toLowerCase()
              ));
              if (accessManagerRole || roleManagerRole) {
                return true;
              }
            }
          }
        }
      }
      return false;
    },
    isOnboardedUser: (state, rootGetters) => {
      // return false;
      const hasUser = state.user && state.user.length > 0;
      if (hasUser || rootGetters.permissions.length || rootGetters.managesAnApp) {
        return true;
      }
      return false;
    },
    expires: state => state.expires,
    requestedRoute: state => state.requestedRoute,
    loggedInUser: state => state.loggedInUser,
    isInitializing: state => state.isInitializing,
    dataMappings: (state) => {
      if (!state.user) {
        return {};
      }
      return state.user.data_mappings;
    },
    permissions: (state) => {
      try {
        const permissions = state.user.applications.find(x => (
          x.name === 'Protego'
        )).environments.find(x => (
          x.name.toLowerCase() === process.env.VUE_APP_CURRENT_ENVIRONMENT.toLowerCase()
        )).permissions;
        return permissions;
      } catch (error) {
        return [];
      }
    },
    permissionsForAllApps: (state) => {
      const permissions = [];
      if (state.user && state.user.applications) {
        for (let appIndex = 0; appIndex < state.user.applications.length; appIndex += 1) {
          const application = state.user.applications[appIndex];
          if (application.environments) {
            for (let envIndex = 0; envIndex < application.environments.length; envIndex += 1) {
              const environment = application.environments[envIndex];
              const hasAccessManagerRole = environment.roles.find(x => (
                x.name.toLowerCase() === process.env.VUE_APP_MAGIC_ROLE_NAME.toLowerCase()
              ));
              const hasRoleManagerRole = environment.roles.find(x => (
                x.name.toLowerCase() === process.env.VUE_APP_SECOND_MAGIC_ROLE_NAME.toLowerCase()
              ));
              if (application.id === process.env.VUE_APP_SECURITY_APP_ID) {
                permissions.push(...environment.permissions.map(permission => ({
                  name: permission.name,
                  application: application.id,
                  environment: environment.name,
                })));
              } else if (hasAccessManagerRole || hasRoleManagerRole) {
                permissions.push(...environment.permissions.map(permission => ({
                  name: permission.name,
                  application: application.id,
                  environment: environment.name,
                })));
              }
            }
          }
        }
      }
      return permissions;
    },
    canCreateApplications: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return getters.permissions.map(x => x.name).indexOf('create_application') > -1;
    },
    canDeleteUsers: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return getters.permissions.map(x => x.name).indexOf('delete_user') > -1;
    },
    canDeleteRoles: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return getters.permissions.map(x => x.name).indexOf('delete_role') > -1;
    },
    canCreateRoles: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return getters.permissions.map(x => x.name).indexOf('create_role') > -1;
    },
    canUpdateRoles: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return getters.permissions.map(x => x.name).indexOf('update_role') > -1;
    },
    canDeletePermissions: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return getters.permissions.map(x => x.name).indexOf('delete_permission') > -1;
    },
    canEditApplications: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return getters.permissions.map(x => x.name).indexOf('edit_application') > -1;
    },
    canCreateUsers: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return getters.permissionsForAllApps.map(x => x.name).indexOf('create_user') > -1;
    },
    canManageUserAppAccess: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return getters.permissionsForAllApps.map(x => x.name).indexOf('manage-access_user') > -1;
    },
    canManageUserDataAccess: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return getters.permissionsForAllApps.map(x => x.name).indexOf('manage-data_user') > -1;
    },
    emailAddress: (state) => {
      if (state.profile && state.profile.identities) {
        return state.profile.identities[0].userId;
      }
      return null;
    },
    firstName: (state, rootGetters) => {
      if (state.profile && state.profile.given_name) {
        return state.profile.given_name;
      } if (rootGetters.emailAddress) {
        return capitalize(rootGetters.emailAddress.split('@')[0].split('.')[0]) || null;
      }
      return null;
    },
    lastName: (state, rootGetters) => {
      if (state.profile && state.profile.family_name) {
        return state.profile.family_name;
      } if (rootGetters.emailAddress) {
        return capitalize(rootGetters.emailAddress.split('@')[0].split('.')[1]) || null;
      }
      return null;
    },
  },

  mutations: {
    setImpersonatingUser: (state, impersonatingUser) => {
      state.impersonatingUser = impersonatingUser;
      state.refreshedAt = null;
      window.localStorage.setItem('protego-auth', JSON.stringify(state));
    },
    setauthorizationCode: (state, authorizationCode) => {
      state.authorizationCode = authorizationCode;
      window.localStorage.setItem('protego-auth', JSON.stringify(state));
    },
    setRefreshToken: (state, refreshToken) => {
      state.refreshToken = refreshToken;
      window.localStorage.setItem('protego-auth', JSON.stringify(state));
    },
    setauthorizationState: (state, authorizationState) => {
      state.authorizationState = authorizationState;
      window.localStorage.setItem('protego-auth', JSON.stringify(state));
    },
    setredirectUri: (state, redirectUri) => {
      state.redirectUri = redirectUri;
      window.localStorage.setItem('protego-auth', JSON.stringify(state));
    },
    setProfile: (state, profile) => {
      // eslint-disable-next-line no-param-reassign
      state.profile = profile;
      window.localStorage.setItem('protego-auth', JSON.stringify(state));
    },
    setAccessToken: (state, accessToken) => {
      // eslint-disable-next-line no-param-reassign
      state.accessToken = accessToken;
      window.localStorage.setItem('protego-auth', JSON.stringify(state));
    },
    setIdToken: (state, idToken) => {
      // eslint-disable-next-line no-param-reassign
      state.idToken = idToken;
      window.localStorage.setItem('protego-auth', JSON.stringify(state));
    },
    setVerification: (state, verification) => {
      // eslint-disable-next-line no-param-reassign
      state.verification = verification;
      window.localStorage.setItem('protego-auth', JSON.stringify(state));
    },
    setAuthenticated: (state, isAuthenticated) => {
      // eslint-disable-next-line no-param-reassign
      state.isAuthenticated = isAuthenticated;
      window.localStorage.setItem('protego-auth', JSON.stringify(state));
    },
    setRequestedRoute: (state, route) => {
      // eslint-disable-next-line no-param-reassign
      state.requestedRoute = route;
      window.localStorage.setItem('protego-auth', JSON.stringify(state));
    },
    setExpires: (state, expires) => {
      // eslint-disable-next-line no-param-reassign
      state.expires = expires;
      window.localStorage.setItem('protego-auth', JSON.stringify(state));
    },
    setUserData: (state, user) => {
      // eslint-disable-next-line no-param-reassign
      state.loggedInUser = user;
      window.localStorage.setItem('protego-auth', JSON.stringify(state));
    },
    setInitializing: (state, init) => {
      // eslint-disable-next-line no-param-reassign
      state.isInitializing = init;
      window.localStorage.setItem('protego-auth', JSON.stringify(state));
    },
    setPermissions: (state, perms) => {
      // eslint-disable-next-line no-param-reassign
      state.permissions = perms;
      window.localStorage.setItem('protego-auth', JSON.stringify(state));
    },
    setRefreshed: (state, ref) => {
      // eslint-disable-next-line no-param-reassign
      state.refreshedAt = ref;
      window.localStorage.setItem('protego-auth', JSON.stringify(state));
    },
    setUser: (state, user) => {
      state.user = user;
      if (user === null) {
        state.refreshedAt = null;
      } else {
        state.refreshedAt = new Date();
      }
      window.localStorage.setItem('protego-auth', JSON.stringify(state));
    },
  },

  actions: {
    signout(context) {
      context.commit('setProfile', null);
      context.commit('setAccessToken', null);
      context.commit('setAuthenticated', false);
      context.commit('setExpires', null);
      router.push({ name: 'Login' });
    },

    setUserPermissions(context) {
      const pId = process.env.VUE_APP_SECURITY_APP_ID;
      const user = context.state.loggedInUser;
      if (user && user.applications) {
        const userIsInProtego = user && user.applications.filter(x => x.id === pId).length > 0;
        if (userIsInProtego) {
          const protego = user.applications.find(x => x.id === pId);
          context.commit('setPermissions', protego.permissions);
        }
      }
    },

    authenticate(context, payload) {
      return new Promise((resolve) => {
        context.commit('setauthorizationCode', payload.authorizationCode);
        context.commit('setauthorizationState', payload.state);
        context.dispatch('getToken').then((onboarded) => {
          resolve(onboarded);
        });
      });
    },

    getToken(context) {
      options.data.authorization_code = context.state.authorizationCode;
      options.data.redirect_uri = context.state.redirectUri;
      return endpoints.post(options).then((response) => {
        context.dispatch('setToken', response.data);
        return response.data;
      });
    },

    setProfile(context, profile) {
      context.state.profile = profile;
      return context.dispatch('getUser', false).then(
        () => context.getters.isOnboardedUser,
        () => new Error('Error retrieving user'),
      );
    },

    getUser(context, login) {
      const refreshedMoment = dayjs(context.state.refreshedAt);
      const doesntHaveEnvironments = (
        !context.state.user
        || !context.state.user.applications
        || !context.state.user.applications.length
        || !context.state.user.applications[0].environments
      );
      if (
        doesntHaveEnvironments
        || !context.state.refreshedAt
        || refreshedMoment.add(2, 'minutes').isBefore(dayjs())
      ) {
        return context.dispatch('getUserFromApi', login).then((user) => {
          context.commit('setUser', user);
          return user;
        }, () => {
          context.commit('setUser', null);
          return null;
        });
      }
      return new Promise(resolve => resolve(context.state.user));
    },

    refreshToken(context) {
      options.endpoint = 'auth/refresh';
      options.data.refresh_token = context.state.refreshToken;
      options.data.redirect_uri = context.state.redirectUri;
      return endpoints.post(options).then((response) => {
        if (!response.data || response.data.error) {
          context.commit('setIdToken', null);
          context.commit('setAccessToken', null);
          context.commit('setRefreshToken', null);
          const expires = dayjs().add(53, 'minutes').valueOf();
          context.commit('setExpires', expires);
          return null;
        }
        context.dispatch('setNewToken', response.data);
        return response.data.access_token;
      }, () => {
        context.commit('setIdToken', null);
        context.commit('setAccessToken', null);
        context.commit('setRefreshToken', null);
        const expires = dayjs().add(53, 'minutes').valueOf();
        context.commit('setExpires', expires);
        return null;
      });
    },

    setNewToken(context, payload) {
      const profile = jwt(payload.id_token);
      context.commit('setIdToken', payload.id_token);
      context.commit('setAccessToken', payload.access_token);
      const expires = dayjs().add(53, 'minutes').valueOf();
      context.commit('setExpires', expires);
      context.dispatch('setProfile', profile);
      context.commit('setAuthenticated', true);
    },

    getUserFromApi(context) {
      const vm = this;
      let email = context.state.profile.identities[0].userId;
      if (context.state.impersonatingUser) {
        email = context.state.impersonatingUser;
      }
      return vm.$http.get(`${process.env.VUE_APP_API_ROOT}/users/${email}?environment=all`).then(response => response.data, () => new Error('Error retrieving user'));
    },

    setToken(context, payload) {
      const profile = jwt(payload.id_token);
      context.commit('setIdToken', payload.id_token);
      context.commit('setAccessToken', payload.access_token);
      context.commit('setRefreshToken', payload.refresh_token);
      const expires = dayjs().add(53, 'minutes').valueOf();
      context.commit('setExpires', expires);
      context.dispatch('setProfile', profile);
      context.commit('setAuthenticated', true);
    },

    setUserData(context, user) {
      context.commit('setUserData', user);
    },
  },
};

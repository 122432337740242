/* eslint-disable no-param-reassign */
import endpoints from '../../api/endpoints';

// initial state
const state = {
  all: [],
};

const options = {};

// actions
const actions = {

  get(st, id) {
    st.commit('progress/setProcessing', 'permissions/get', { root: true });
    options.endpoint = `applications/${id}/permissions`;
    return endpoints.list(options, st).then((response) => {
      const permissions = response.data;
      st.commit('setPermissions', permissions);
      st.commit('progress/removeProcessing', 'permissions/get', { root: true });
      return permissions;
    }, () => {
      st.commit('progress/removeProcessing', 'permissions/get', { root: true });
    });
  },

  save(st, data) {
    st.commit('setSaveStatus', null);
    st.commit('progress/setProcessing', 'permissions/save', { root: true });
    options.data = data;
    options.endpoint = `applications/${data.id}/permissions`;
    return endpoints.create(options, st).then((response) => {
      st.commit('setSaveStatus', 'successful');
      st.commit('progress/removeProcessing', 'permissions/save', { root: true });
      return response.data;
    },
    () => {
      st.commit('setSaveStatus', 'failed');
      st.commit('progress/removeProcessing', 'permissions/save', { root: true });
      st.commit('progress/setError', 'Error saving the permission', { root: true });
    });
  },

  delete_permission(st, data) {
    st.commit('progress/setProcessing', 'permissions/delete_permission', { root: true });
    options.path = `applications/${data.applicationId}/permissions/${data.slug}`;
    return endpoints.delete(options, st).then((response) => {
      const permissions = response.data;
      st.commit('setPermissions', permissions);
      st.commit('progress/removeProcessing', 'permissions/delete_permission', { root: true });
      return permissions;
    }, () => {
      st.commit('setSaveStatus', 'failed');
      st.commit('progress/removeProcessing', 'permissions/delete_permission', { root: true });
      st.commit('progress/setError', 'Error deleting the permission', { root: true });
    });
  },

  setSaveStatus({ commit }, status) {
    commit('setSaveStatus', status);
  },

};

// mutations
const mutations = {
  setPermissions(st, permissions) {
    const theState = st;
    theState.all = permissions;
  },

  setSaveStatus(st, status) {
    const theState = state;
    theState.saveStatus = status;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};

<template id="icon-button-template">
  <button
    :class="`btn btn-${variant} btn-${size}`"
    :disabled="disabled"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    :style="btnStyle"
    @click="$emit('click');"
    >
    <span class="buttonText" v-if="!noText">
      <span class="btn-text">{{text}}</span>
    </span>
  </button>
</template>
<style lang="scss" scoped>
  .btn {
    background-repeat: no-repeat;
  }
</style>
<script>
export default {
  name: 'IconButton',
  template: '#icon-button-template',
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    src() {
      if (this.hover) {
        return this.hoverIconPath;
      }
      if (this.disabled === true) {
        return this.disabledIconPath;
      }
      return this.icon;
    },
    noText() {
      return this.text === undefined;
    },
    btnStyle() {
      const style = { 'background-image': `url(${this.src})` };
      if (this.noText) {
        if (this.size === 'lg') {
          style.width = '29px';
          style.height = '29px';
        } else {
          style.width = '20px';
          style.height = '20px';
        }
        style.padding = 0;
        style['background-size'] = 'auto 60%';
        style['background-position'] = 'center center';
      } else {
        let iconPadding = '45px';
        if (this.size === 'sm') {
          iconPadding = '25px';
        }
        if (this.size === 'sm') {
          style['background-size'] = 'auto 40%';
        } else {
          style['background-size'] = 'auto 60%';
        }
        if (this.placement === 'right') {
          style['padding-right'] = iconPadding;
          style['background-position'] = 'center right 5px';
        } else {
          style['padding-left'] = iconPadding;
          style['background-position'] = 'center left 5px';
        }
      }
      return style;
    },
    hoverIconPath() {
      if (this.hoverIcon !== undefined) {
        return this.hoverIcon;
      }
      return this.icon;
    },
    disabledIconPath() {
      if (this.disabledIcon !== undefined) {
        return this.disabledIcon;
      }
      return this.icon;
    },
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    hoverIcon: {
      type: String,
      required: false,
    },
    disabledIcon: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
    },
    variant: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'left',
    },
  },
};
</script>

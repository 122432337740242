<template>
  <div class="w-100">
    <div
      class="alert alert-dismissible alert-success"
      role="alert"
      aria-live="polite"
      aria-atomic="true"
      enterclass=""
      leaveclass="show"
      v-if="successMessage !== null"
    >
      <button class="close" aria-label="Close" type="button" @click="resetMessages">×</button>{{successMessage}}</div>
    <div
      class="alert alert-dismissible alert-danger"
      role="alert"
      aria-live="polite"
      aria-atomic="true"
      enterclass=""
      leaveclass="show"
      v-if="errorMessage !== null"
    >
      <button class="close" aria-label="Close" type="button" @click="resetMessages">×</button>{{errorMessage}}</div>
  </div>
</template>
<style>
#body-container > .container-fluid > .w-100 [class*=alert] {
  max-width: calc(100% - 90px);
}
</style>
<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'MessageAlerts',
  computed: {
    ...mapState({
      errorMessage: state => state.progress.errorMessage,
      successMessage: state => state.progress.successMessage,
    }),
  },
  methods: {
    ...mapActions({
      setErrorMessage: 'progress/setErrorMessage',
      setSuccessMessage: 'progress/setSuccessMessage',
    }),
    resetMessages() {
      this.setSuccessMessage(null);
      this.setErrorMessage(null);
    },
  },
  watch: {
    errorMessage() {
      const vm = this;
      if (vm.errorMessage !== null) {
        // We originally displayed the message for only 5 seconds, but if the message is long, we
        // want to adjust the length of time accordingly
        const duration = 5000 + (Math.round(vm.errorMessage.length / 100) * 5000);
        setTimeout(() => {
          vm.resetMessages();
        }, duration);
      }
    },
    successMessage() {
      const vm = this;
      if (vm.successMessage !== null) {
        // We originally displayed the message for only 5 seconds, but if the message is long, we
        // want to adjust the length of time accordingly
        const duration = 5000 + (Math.round(vm.successMessage.length / 100) * 5000);
        setTimeout(() => {
          vm.resetMessages();
        }, duration);
      }
    },
  },
};
</script>

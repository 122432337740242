<template>
  <div class="box-shadow">
    <slot></slot>
    <div
      class="tableFoot bg-white"
      v-if="hasFooter">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TableContainer',
  computed: {
    hasFooter() {
      return !!this.$slots.footer;
    },
  },
};
</script>

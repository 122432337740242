<template>
  <div class="card"
    :key="id"
    :id="`${id}-card`"
    no-body
  >
    <div
      class="card-header p-0"
      :id="`${id}-heading`"
      @click="headerClick"
    >
      <button
        class="btn btn-link"
        variant="link"
        type="button"
        data-toggle="collapse"
        :aria-controls="`collapse-${id}`"
      >{{title}}</button>
      <add-icon
        class="float-right"
        animation="minus"
        :active="active"
      ></add-icon>
    </div>
    <div
      class="collapse"
      :class="{'show': active}"
      :id="`collapse-${id}`"
      :aria-labelledby="`${id}-heading`"
      :data-parent="`#${accordion_id}`"
    >
      <div class="card-body">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Accordion',
  methods: {
    headerClick() {
      const vm = this;
      if (!vm.active) {
        vm.$emit('accordion-selected', vm.id);
      }
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    accordion_id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

import axios from 'axios';

const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API_ROOT,
});

export default {
  post(options) {
    return HTTP.post(`/${options.endpoint}`, options.data);
  },
};

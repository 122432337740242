import http from '@/api/http';

export default {
  list(options, store) {
    let state = store.rootState;
    if (!state) {
      state = store.state;
    }
    if (!state.auth.accessToken) {
      return new Promise((resolve, reject) => reject());
    }
    let url = `/${options.endpoint}`;
    if (options.parameters) {
      for (let i = 0; i < options.parameters.length; i += 1) {
        const param = options.parameters[i];
        url = `${url}${i === 0 ? '?' : '&'}${encodeURIComponent(param.name)}=${encodeURIComponent(param.value)}`;
      }
    }
    return http.get(url);
  },

  get(options, store) {
    let state = store.rootState;
    if (!state) {
      state = store.state;
    }
    if (!state.auth.accessToken) {
      return new Promise((resolve, reject) => reject());
    }
    let url;
    if (options.path) {
      url = options.path;
    } else {
      url = `/${options.endpoint}/${options.id}`;
    }
    if (options.parameters) {
      for (let i = 0; i < options.parameters.length; i += 1) {
        const param = options.parameters[i];
        url = `${url}${i === 0 ? '?' : '&'}${encodeURIComponent(param.name)}=${encodeURIComponent(param.value)}`;
      }
    }
    return http.get(url);
  },

  create(options, store) {
    let state = store.rootState;
    if (!state) {
      state = store.state;
    }
    if (!state.auth.accessToken) {
      return new Promise((resolve, reject) => reject());
    }
    return http.post(`/${options.endpoint}`, options.data);
  },

  update(options, store) {
    let state = store.rootState;
    if (!state) {
      state = store.state;
    }
    if (!state.auth.accessToken) {
      return new Promise((resolve, reject) => reject());
    }
    if (!options.itemPath) {
      return http.put(`/${options.endpoint}`, options.data);
    }
    return http.put(`/${options.endpoint}/${options.itemPath}`, options.data);
  },

  delete(options, store) {
    let state = store.rootState;
    if (!state) {
      state = store.state;
    }
    if (!state.auth.accessToken) {
      return new Promise((resolve, reject) => reject());
    }
    let url;
    if (options.path) {
      url = options.path;
    } else {
      url = `/${options.endpoint}/${options.id}`;
    }
    return http.delete(url);
  },
};

/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import dayjs from 'dayjs';
import endpoints from '@/api/endpoints';

const defaultEnvironments = [
  {
    name: 'Production',
    url: null,
    roles: [],
  },
  {
    name: 'Development',
    url: null,
    roles: [],
  },
  {
    name: 'Staging',
    url: null,
    roles: [],
  },
];

const defaultApplication = {
  name: '',
  slug: null,
};

// initial state
const state = {
  all: [],
  refreshedAt: null,
  dropdownOptions: [],
  saveStatus: null,
  application: defaultApplication,
  environments: defaultEnvironments,
};

const options = {
  endpoint: 'applications',
};

// actions
const actions = {

  setRolePermissions(st, params) {
    const vm = this;
    const url = `applications/${params.applicationId}/environments/${params.environment}/${params.roleName}/permissions`;
    st.commit('progress/setProcessing', url, { root: true });
    return vm.$http.put(url, params.data).then((response) => {
      const users = response.data;
      st.commit('progress/removeProcessing', url, { root: true });
      st.commit('setApplication', response.data);
      return users;
    },
    () => {
      st.commit('progress/removeProcessing', url, { root: true });
    });
  },

  getAll(st) {
    const refreshedMoment = dayjs(st.state.refreshedAt);
    if (!st.state.refreshedAt || refreshedMoment.add(3, 'minutes').isBefore(dayjs())) {
      st.commit('progress/setProcessing', 'applications/list', { root: true });
      return endpoints.list(options, st).then((response) => {
        const applications = response.data;
        st.commit('setApplications', applications);
        st.commit('progress/removeProcessing', 'applications/list', { root: true });
        return applications;
      });
    }
    return new Promise(resolve => resolve(st.state.all));
  },

  getAllWithEnvironments(st) {
    st.commit('progress/setProcessing', 'applications/list', { root: true });
    const apiOptions = { ...options };
    apiOptions.parameters = [{ name: 'environment', value: 'all' }];
    return endpoints.list(apiOptions, st).then((response) => {
      const applications = response.data;
      st.commit('setApplications', applications);
      st.commit('progress/removeProcessing', 'applications/list', { root: true });
      return applications;
    });
  },

  get(st, id) {
    return st.dispatch('getFromAPI', id).then((app) => {
      st.commit('setApplication', app);
      return app;
    });
  },

  getEnvironments(st, id) {
    return st.dispatch('getEnvironmentsFromAPI', id).then((environments) => {
      st.commit('setEnvironments', environments);
      return environments;
    });
  },

  getFromAPI(st, id) {
    if (!st.state.application || st.state.application.slug !== id) {
      st.commit('progress/setProcessing', 'applications/get', { root: true });
      options.id = id;
      return endpoints.get(options, st).then((response) => {
        const app = response.data;
        st.commit('progress/removeProcessing', 'applications/get', { root: true });
        return app;
      }, () => {
        st.commit('progress/removeProcessing', 'applications/get', { root: true });
      });
    }
    return new Promise(resolve => resolve(st.state.application));
  },

  getEnvironmentsFromAPI(st, id) {
    st.commit('progress/setProcessing', 'environments/get', { root: true });
    const apiOptions = { ...options };
    apiOptions.endpoint = `applications/${id}/environments`;
    return endpoints.list(apiOptions, st).then((response) => {
      const environments = response.data;
      st.commit('progress/removeProcessing', 'environments/get', { root: true });
      for (let i = 0; i < environments.length; i += 1) {
        if (!environments[i].roles) {
          environments[i].roles = [];
        }
      }
      return environments;
    }, () => {
      st.commit('progress/removeProcessing', 'environments/get', { root: true });
    });
  },

  save(st, data) {
    st.commit('setSaveStatus', null);
    st.commit('progress/setProcessing', 'applications/save', { root: true });
    options.data = data;
    return endpoints.create(options, st).then((response) => {
      st.commit('setSaveStatus', 'successful');
      st.commit('setApplication', response.data);
      st.commit('progress/removeProcessing', 'applications/save', { root: true });
      st.commit('setRefreshed', null);
      return response.data;
    },
    () => {
      st.commit('setSaveStatus', 'failed');
      st.commit('progress/removeProcessing', 'applications/save', { root: true });
      st.commit('progress/setError', 'Error saving the application', { root: true });
    });
  },

  update(st, data) {
    st.commit('setSaveStatus', null);
    st.commit('progress/setProcessing', 'applications/update', { root: true });
    options.data = data;
    options.itemPath = data.slug;
    return endpoints.update(options, st).then((response) => {
      st.commit('setSaveStatus', 'successful');
      st.commit('setApplication', response.data);
      st.commit('progress/removeProcessing', 'applications/update', { root: true });
      st.commit('setRefreshed', null);
      return response.data;
    },
    () => {
      st.commit('setSaveStatus', 'failed');
      st.commit('progress/removeProcessing', 'applications/update', { root: true });
      st.commit('progress/setError', 'Error saving the application', { root: true });
    });
  },

  addNewRole(st, data) {
    // st.commit('setSaveStatus', null);
    st.commit('progress/setProcessing', 'applications/addRole', { root: true });
    const apiOptions = { ...options };
    apiOptions.data = data.role;
    apiOptions.endpoint = `applications/${data.slug}/environments/${data.environment}`;
    return endpoints.create(apiOptions, st).then((response) => {
      // st.commit('setSaveStatus', 'successful');
      st.commit('progress/removeProcessing', 'applications/addRole', { root: true });
      st.commit('setRefreshed', null);
      return response.data;
    },
    (error) => {
      const errorMessage = error?.response?.data?.error?.message || 'Error saving the role';
      // st.commit('setSaveStatus', 'failed');
      st.commit('progress/removeProcessing', 'applications/addRole', { root: true });
      st.commit('progress/setError', errorMessage, { root: true });
    });
  },

  deleteRole(st, data) {
    // st.commit('setSaveStatus', null);
    st.commit('progress/setProcessing', 'applications/deleteRole', { root: true });
    const apiOptions = { ...options };
    apiOptions.data = data.role;
    apiOptions.path = `applications/${data.slug}/environments/${data.environment}/${data.roleName}`;
    return endpoints.delete(apiOptions, st).then((response) => {
      // st.commit('setSaveStatus', 'successful');
      st.commit('progress/removeProcessing', 'applications/deleteRole', { root: true });
      st.commit('setRefreshed', null);
      return response.data;
    },
    () => {
      // st.commit('setSaveStatus', 'failed');
      st.commit('progress/removeProcessing', 'applications/deleteRole', { root: true });
      st.commit('progress/setError', 'Error saving the role', { root: true });
    });
  },

  setSaveStatus({ commit }, status) {
    commit('setSaveStatus', status);
  },

  clear(st) {
    st.commit('setRefreshed', null);
    st.commit('setApplication', defaultApplication);
  },
};

// mutations
const mutations = {
  setApplications(st, applications) {
    const theState = st;
    theState.all = applications;
    theState.refreshedAt = new Date();
    const opts = [{ text: '', id: '' }];
    opts.push(...applications.map(x => ({
      text: x.name,
      id: x.id,
    })));
    theState.dropdownOptions = opts;
  },

  setRefreshed(st, refreshed) {
    // eslint-disable-next-line no-param-reassign
    st.refreshedAt = refreshed;
  },

  setApplication(st, application) {
    const theState = st;
    theState.application = application;
  },

  setEnvironments(st, environments) {
    const theState = st;
    theState.environments = environments.map((environment) => {
      const env = environment;
      if (env.roles && env.roles.length > 0) {
        const newRoles = env.roles.map((role) => {
          const r = role;
          if (!r.permissions || r.permissions.length === 0) {
            r.deletable = true;
          } else {
            r.deletable = false;
          }
          return r;
        });
        env.roles = newRoles;
      }
      return env;
    });
  },

  resetEnvironments(st) {
    const theState = st;
    theState.environments = defaultEnvironments;
  },


  setSaveStatus(st, status) {
    const theState = state;
    theState.saveStatus = status;
  },
};

const getters = {
  applications: (st) => {
    if (!st.all) {
      return [];
    }
    return st.all;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};

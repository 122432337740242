/* eslint-disable no-param-reassign */

import endpoints from '../../api/endpoints';

// initial state
const state = {
  all: [],
  dropdownOptions: [
    { text: '', id: null },
    { text: 'Web', id: 'web', info: 'Used for User to Application Interaction' },
    { text: 'API', id: 'api', info: 'Used for Application to Application Interaction' },
  ],
  appClient: {},
  saveStatus: null,
};

// getters
const getters = {
};

const options = {};

// actions
const actions = {
  get(st, settings) {
    options.path = `appclients/${settings.id}/details`;
    st.commit('progress/setProcessing', 'appClients/read', { root: true });
    return endpoints.get(options, st).then((response) => {
      const appClient = response.data;
      st.commit('setAppClient', appClient);
      st.commit('progress/removeProcessing', 'appClients/read', { root: true });
      return appClient;
    }, () => {
      st.commit('progress/removeProcessing', 'appClients/read', { root: true });
    });
  },

  getAll(st, settings) {
    options.endpoint = `applications/${settings.app}/environments/${settings.environment}/appclients`;
    st.commit('progress/setProcessing', 'appClients/list', { root: true });
    return endpoints.list(options, st).then((response) => {
      const appClients = response.data;
      st.commit('setAppClients', appClients);
      st.commit('progress/removeProcessing', 'appClients/list', { root: true });
      return appClients;
    });
  },

  save(st, settings) {
    options.endpoint = `applications/${settings.app}/environments/${settings.environment}/appclients`;
    st.commit('setSaveStatus', null);
    st.commit('progress/setProcessing', 'appClients/save', { root: true });
    options.data = settings.data;
    return endpoints.create(options, st).then((response) => {
      const appClients = st.state.all;
      appClients.push(response.data);
      st.commit('setSaveStatus', 'successful');
      st.commit('setAppClient', response.data);
      st.commit('setAppClients', appClients);
      st.commit('progress/removeProcessing', 'appClients/save', { root: true });
      return response.data;
    },
    () => {
      st.commit('setSaveStatus', 'failed');
      st.commit('progress/removeProcessing', 'appClients/save', { root: true });
      st.commit('progress/setError', 'Error saving the app client', { root: true });
    });
  },

  update(st, settings) {
    options.endpoint = `applications/${settings.app}/environments/${settings.environment}/appclients`;
    st.commit('setSaveStatus', null);
    st.commit('progress/setProcessing', 'appClients/update', { root: true });
    options.data = settings.data;
    options.itemPath = settings.data.id;
    return endpoints.update(options, st).then((response) => {
      st.commit('setSaveStatus', 'successful');
      st.commit('setAppClient', response.data);
      st.commit('progress/removeProcessing', 'appClients/update', { root: true });
      return response.data;
    },
    () => {
      st.commit('setSaveStatus', 'failed');
      st.commit('progress/removeProcessing', 'appClients/update', { root: true });
      st.commit('progress/setError', 'Error saving the app client', { root: true });
    });
  },

  delete(st, settings) {
    options.path = null;
    options.endpoint = 'appclients';
    options.id = settings.id;
    st.commit('setSaveStatus', null);
    st.commit('progress/setProcessing', 'appClients/delete', { root: true });
    return endpoints.delete(options, st).then((response) => {
      st.commit('setSaveStatus', 'successful');
      st.commit('setAppClient', response.data);
      st.commit('progress/removeProcessing', 'appClients/delete', { root: true });
      return response.data;
    },
    () => {
      st.commit('setSaveStatus', 'failed');
      st.commit('progress/removeProcessing', 'appClients/delete', { root: true });
      st.commit('progress/setError', 'Error deleting the app client', { root: true });
    });
  },

  setSaveStatus({ commit }, status) {
    commit('setSaveStatus', status);
  },
};

// mutations
const mutations = {
  setAppClients(st, appClients) {
    const theState = st;
    theState.all = appClients;
  },

  setAppClient(st, appClient) {
    const theState = st;
    theState.appClient = appClient;
  },

  setSaveStatus(st, status) {
    const theState = state;
    theState.saveStatus = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

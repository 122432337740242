<template>
  <app-layout
    app_name="Protego"
    :breadcrumb_trail="breadcrumbTrail"
    :user_access_token="user_access_token"
    :user_email="$store.getters.emailAddress"
    :first_name="$store.getters.firstName"
    :last_name="$store.getters.lastName"
    :show_profile="profile !== null"
    :menu_items="permittedMenuItems"
    :container_class="containerClass"
    :key="authExpires"
  >
  <template v-slot:profile-controls>
    <impersonate-selector
      v-if="hasPermissionForApplication(
        'impersonate_user',
        applicationId,
        environment
      )"
    />
  </template>
  <impersonate-header v-if="$store.state.auth.impersonatingUser" />
    <MessageAlerts /><router-view/>
    <template v-slot:after-footer>
      <loading-spinner v-if="loadingModules.length > 0" />
      <a-modal
        ref="confirmModal"
        :ok-title="confirmModal.okText"
        :cancel-title="confirmModal.cancelText"
        @ok="confirmModal.okFunction"
        @cancel="confirmModal.cancelFunction"
        cancel-variant="light"
        v-model="confirmModalVisible">
        <p class="my-2">{{confirmModal.text}}</p>
      </a-modal>
    </template>
  </app-layout>
</template>
<style>
:root {
    --popper-theme-background-color: #333333;
    --popper-theme-background-color-hover: #333333;
    --popper-theme-text-color: #ffffff;
    --popper-theme-border-width: 0px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 5px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  }
</style>
<style lang="scss">
.modal.show {
  display: block;
}
.darkModal {
  .autocomplete__box {
    background-color: transparent;
    padding: 0;
  }
}
</style>
<script>
import { mapGetters, mapState } from 'vuex';
import dayjs from 'dayjs';
import confirm from '@/mixins/confirm';
import router from './router';
import ImpersonateHeader from './components/ImpersonateHeader';
import ImpersonateSelector from './components/ImpersonateSelector';
import MessageAlerts from './components/MessageAlerts';

export default {
  name: 'Protego',
  router,
  components: {
    MessageAlerts,
    ImpersonateHeader,
    ImpersonateSelector,
  },
  data() {
    return {
      initializing: true,
      confirmModalVisible: false,
      confirmModal: {
        text: '',
        okText: '',
        cancelText: '',
        okFunction: () => {},
        cancelFunction: () => {},
      },
      breadcrumbTrail: [],
    };
  },
  mixins: [confirm],
  computed: {
    ...mapGetters([
      'profile',
      'permissions',
      'isAuthenticated',
    ]),
    ...mapGetters({
      permittedMenuItems: 'mainmenu/permittedMenuItems',
      isOnboardedUser: 'isOnboardedUser',
      permissionsForAllApps: 'permissionsForAllApps',
    }),
    ...mapState({
      loadingModules: state => state.progress.loadingModules,
      user_access_token: state => state.auth.accessToken,
      authExpires: state => state.auth.expires,
    }),
    environment() {
      return process.env.VUE_APP_CURRENT_ENVIRONMENT;
    },
    applicationId() {
      return process.env.VUE_APP_SECURITY_APP_ID;
    },
    containerClass() {
      if (this.$route.meta && this.$route.meta.fullWidth === true) {
        return 'container-fluid';
      }
      return 'container';
    },
  },
  methods: {
    initializeData() {
      const vm = this;
      const dataPoints = ['users', 'channels', 'clients', 'markets', 'agencies'];
      for (let i = 0; i < dataPoints.length; i += 1) {
        vm.$store.dispatch(`${dataPoints[i]}/getAll`);
      }
    },
    dayjs(date) {
      return dayjs(date);
    },
    updateUserRoles(route) {
      const vm = this;
      vm.initializing = true;
      if (vm.profile !== null && route && route.meta && !route.meta.allowAnonymous) {
        const email = vm.profile.identities[0].userId;
        const promises = [
          vm.$store.dispatch('users/getFromAPI', email),
        ];
        return Promise.all(promises).then(() => {
          vm.initializing = false;
          return true;
        }, () => {
          vm.initializing = false;
          return true;
        });
      }
      return new Promise((resolve) => {
        vm.initializing = false;
        return resolve(false);
      });
    },
    setUpBreadcrumb() {
      const vm = this;
      vm.$router.afterEach((to) => {
        vm.breadcrumbTrail = to.meta.breadcrumbTrail;
      });
      vm.breadcrumbTrail = vm.$route.meta.breadcrumbTrail;
    },
    hasTopLevelEntityValue(entity, value) {
      const vm = this;
      if (vm.dataMappings[entity]) {
        if (vm.dataMappings[entity] === '*' || vm.dataMappings[entity].indexOf(value) > -1) {
          return true;
        }
      }
      return false;
    },
    hasPermissionForEntityValue(permission, entity, value) {
      const vm = this;
      const userPermission = vm.permissions.find(x => x.name === permission);
      if (!userPermission) {
        return false;
      }
      if (userPermission.data_profiles) {
        for (let i = 0; i < userPermission.data_profiles.length; i += 1) {
          const profile = userPermission.data_profiles[i];
          if (profile.data_mappings[entity]) {
            if (profile.data_mappings[entity] === '*' || profile.data_mappings[entity].indexOf(value) > -1) {
              return true;
            }
            return false;
          }
        }
      }
      return vm.hasTopLevelEntityValue(entity, value);
    },
    hasPermissionForApplication(permission, applicationId, environment) {
      const vm = this;
      if (environment) {
        return vm.permissionsForAllApps.find(x => (
          x.name.toLowerCase() === permission.toLowerCase()
          && x.application === applicationId
          && x.environment.toLowerCase() === environment.toLowerCase()
        ) || (
          applicationId !== process.env.VUE_APP_SECURITY_APP_ID
          && vm.hasPermissionForApplication(
            permission,
            process.env.VUE_APP_SECURITY_APP_ID,
            environment,
          )
        ));
      }
      return vm.permissionsForAllApps.find(x => (
        x.name.toLowerCase() === permission.toLowerCase()
        && x.application === applicationId
      ) || (
        applicationId !== process.env.VUE_APP_SECURITY_APP_ID
        && vm.hasPermissionForApplication(
          permission,
          process.env.VUE_APP_SECURITY_APP_ID,
        )
      ));
    },
    hasPermissionForAnyApplication(permission, environment) {
      const vm = this;
      if (environment) {
        return vm.permissionsForAllApps.find(x => (
          x.name.toLowerCase() === permission.toLowerCase()
          && x.environment.toLowerCase() === environment.toLowerCase()
        ));
      }
      return vm.permissionsForAllApps.find(x => (
        x.name.toLowerCase() === permission.toLowerCase()
      ));
    },
  },
  watch: {
    breadcrumbTrail: {
      deep: true,
      handler: function breadcrumbTrailHandler(trail) {
        if (trail) {
          const reversed = [{ title: 'Protego' }, ...trail].reverse();
          const title = reversed.map((crumb) => {
            if (crumb.title) {
              return crumb.title;
            }
            return crumb.name;
          }).join(' - ');
          document.title = title;
        }
      },
    },
  },
  created() {
    this.setUpBreadcrumb();
  },
};
</script>

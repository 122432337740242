import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import auth from '@/services/auth';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/profile',
      component: () => import('./views/Profile'),
      beforeEnter: (_to, _from, next) => {
        const promises = [
          store.dispatch('applications/getAllWithEnvironments'),
          store.dispatch(
            'users/getWithAllEnvironments',
            store.getters.profile.identities[0].userId,
            { root: true },
          ),
        ];
        Promise.all(promises).then((resp) => {
          store.dispatch('setUserData', resp[1]).then(() => {
            next();
          });
        });
      },
    },
    {
      path: '/applications',
      component: () => import('./views/Applications'),
      meta: {
        title: 'Applications',
        auth: true,
        allowAppManagers: true,
      },
      children: [
        {
          path: '',
          name: 'Applications',
          component: () => import('./views/Application/Index'),
          meta: {
            auth: true,
            breadcrumbTrail: [
              {
                name: 'Applications',
              },
            ],
          },
          beforeEnter: (_to, _from, next) => {
            store.dispatch('applications/getAll');
            next();
          },
        },
        {
          path: 'create',
          name: 'Create Application',
          component: () => import('./views/Application/Create'),
          meta: {
            auth: true,
            breadcrumbTrail: [
              {
                name: 'Applications',
              },
              {
                name: 'Create Application',
              },
            ],
          },
          beforeEnter: (_to, _from, next) => {
            store.commit('applications/resetEnvironments');
            next();
          },
        },
        {
          path: ':slug',
          component: () => import('./views/Application/Application'),
          props: true,
          children: [
            {
              path: '',
              name: 'applicationsRead',
              component: () => import('./views/Application/Read'),
              props: true,
              beforeEnter: (to, _from, next) => {
                const promises = [
                  store.dispatch('applications/get', to.params.slug),
                  store.dispatch('applications/getEnvironments', to.params.slug),
                ];
                Promise.all(promises).then((responses) => {
                  const destination = to;
                  destination.meta.breadcrumbTrail[1].params = to.params;
                  destination.meta.breadcrumbTrail[1].title = responses[0].name;
                  next();
                });
              },
              meta: {
                title: 'Edit',
                auth: true,
                breadcrumbTrail: [
                  {
                    name: 'Applications',
                  },
                  {
                    name: 'applicationsRead',
                    title: null,
                  },
                ],
              },
            },
            {
              path: 'permissions',
              name: 'Permissions',
              component: () => import('./views/Application/Permissions/Index'),
              props: true,
              beforeEnter: (to, _from, next) => {
                const promises = [
                  store.dispatch('permissions/get', to.params.slug),
                  store.dispatch('applications/get', to.params.slug),
                ];
                Promise.all(promises).then((response) => {
                  const destination = to;
                  destination.meta.breadcrumbTrail[1].title = response[1].name;
                  destination.meta.breadcrumbTrail[2].title = 'Manage Permission';
                  next();
                });
              },
              meta: {
                title: 'Manage Permission',
                auth: true,
                breadcrumbTrail: [
                  {
                    name: 'Applications',
                  },
                  {
                    name: 'applicationsRead',
                    title: null,
                  },
                  {
                    name: 'Permissions',
                    title: null,
                  },
                ],
              },
            },
            {
              path: 'roles/:roleName/:environment',
              name: 'Role',
              component: () => import('./views/Application/Roles/Read'),
              props: true,
              beforeEnter: (to, _from, next) => {
                const promises = [
                  store.dispatch('permissions/get', to.params.slug),
                  store.dispatch('applications/get', to.params.slug),
                  store.dispatch('applications/getEnvironments', to.params.slug),
                ];
                Promise.all(promises).then((app) => {
                  const destination = to;
                  destination.meta.breadcrumbTrail[1].params = {
                    slug: to.params.slug,
                  };
                  destination.meta.breadcrumbTrail[1].title = app[1].name;
                  destination.meta.breadcrumbTrail[2].params = to.params;
                  destination.meta.breadcrumbTrail[2].title = to.params.roleName;
                  next();
                  // store.dispatch('breadcrumbs/set', updatedTrail);
                });
                store.dispatch('users/getFiltered', {
                  applicationId: to.params.slug,
                  roleName: to.params.roleName,
                  environment: to.params.environment,
                });
              },
              meta: {
                title: 'Role',
                auth: true,
                breadcrumbTrail: [
                  {
                    name: 'Applications',
                  },
                  {
                    name: 'applicationsRead',
                    title: null,
                  },
                  {
                    name: 'Role',
                    title: null,
                  },
                ],
              },
            },
            {
              path: 'appclients',
              name: 'App Clients',
              component: () => import('./views/Application/AppClients/Index'),
              props: true,
              beforeEnter: (to, _from, next) => {
                store.dispatch('permissions/get', to.params.slug);
                const destination = to;
                store.dispatch('applications/get', to.params.slug).then((app) => {
                  destination.meta.breadcrumbTrail[1].params = {
                    slug: to.params.slug,
                  };
                  destination.meta.breadcrumbTrail[1].title = app.name;
                  destination.meta.breadcrumbTrail[2].params = to.params;
                  next();
                  // store.dispatch('breadcrumbs/set', updatedTrail);
                });
                store.dispatch('appClients/getAll', {
                  app: to.params.slug,
                  environment: 'all',
                });
              },
              meta: {
                title: 'App Clients',
                auth: true,
                breadcrumbTrail: [
                  {
                    name: 'Applications',
                  },
                  {
                    name: 'applicationsRead',
                    title: null,
                  },
                  {
                    name: 'App Clients',
                    title: 'App Clients',
                  },
                ],
              },
            },
            {
              path: 'appclients/:appClientId',
              name: 'App Client',
              component: () => import('./views/Application/AppClients/Read'),
              props: true,
              beforeEnter: (to, _from, next) => {
                store.dispatch('appClients/get', {
                  app: to.params.slug,
                  id: to.params.appClientId,
                });
                store.dispatch('permissions/get', to.params.slug);
                const destination = to;
                store.dispatch('applications/get', to.params.slug).then((app) => {
                  destination.meta.breadcrumbTrail[1].params = {
                    slug: to.params.slug,
                  };
                  destination.meta.breadcrumbTrail[1].title = app.name;
                  destination.meta.breadcrumbTrail[2].params = to.params;
                  destination.meta.breadcrumbTrail[3].params = to.params;
                  next();
                  // store.dispatch('breadcrumbs/set', updatedTrail);
                });
              },
              meta: {
                title: 'App Clients',
                auth: true,
                breadcrumbTrail: [
                  {
                    name: 'Applications',
                  },
                  {
                    name: 'applicationsRead',
                    title: null,
                  },
                  {
                    name: 'App Clients',
                    title: null,
                  },
                  {
                    name: 'App Client',
                    title: null,
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      path: '/users',
      component: () => import('./views/Users'),
      meta: {
        title: 'Users',
        auth: true,
      },
      children: [
        {
          path: '',
          name: 'Users',
          component: () => import('./views/User/Index'),
          beforeEnter: (_to, _from, next) => {
            store.dispatch('users/getAll');
            next();
          },
          meta: {
            auth: true,
            breadcrumbTrail: [
              {
                name: 'Users',
              },
            ],
          },
        },
        {
          path: 'create',
          name: 'Create User',
          component: () => import('./views/User/Create'),
          beforeEnter: (_to, _from, next) => {
            store.dispatch('mdm/getEntityNames');
            next();
          },
          meta: {
            auth: true,
            breadcrumbTrail: [
              {
                name: 'Users',
              },
              {
                name: 'Create User',
              },
            ],
          },
        },
        {
          path: ':email',
          component: () => import('./views/User/User'),
          props: true,
          children: [
            {
              path: '',
              name: 'usersRead',
              component: () => import('./views/User/Read'),
              props: true,
              beforeEnter: (to, _from, next) => {
                const destination = to;
                destination.meta.breadcrumbTrail[1].params = to.params;
                destination.meta.breadcrumbTrail[1].title = to.params.email;
                const promises = [
                  store.dispatch('applications/getAllWithEnvironments'),
                  store.dispatch('users/getWithAllEnvironments', to.params.email),
                  store.dispatch('mdm/getEntityNames'),
                ];
                Promise.all(promises).then(() => {
                  next();
                });
                store.dispatch('mdm/getEntityNames');
              },
              meta: {
                title: 'Edit',
                auth: true,
                breadcrumbTrail: [
                  {
                    name: 'Users',
                  },
                  {
                    name: 'usersRead',
                    title: 'User',
                  },
                ],
              },
            },
            {
              path: 'dataprofiles',
              component: () => import('./views/User/DataProfiles/Index'),
              props: true,
              name: 'DataProfiles',
              beforeEnter: (to, _from, next) => {
                const destination = to;
                destination.meta.breadcrumbTrail[1].params = to.params;
                destination.meta.breadcrumbTrail[1].title = to.params.email;
                const promises = [
                  store.dispatch('applications/getAllWithEnvironments'),
                  store.dispatch('users/getWithAllEnvironments', to.params.email),
                ];
                Promise.all(promises).then(() => {
                  next();
                });
              },
              meta: {
                title: 'Data Profiles',
                auth: true,
                breadcrumbTrail: [
                  {
                    name: 'Users',
                  },
                  {
                    name: 'usersRead',
                    title: 'User',
                  },
                ],
              },
            },
            {
              path: 'dataprofiles/:application',
              component: () => import('./views/User/DataProfiles/Application'),
              props: true,
              name: 'ApplicationDataProfiles',
              beforeEnter: (to, _from, next) => {
                const destination = to;
                destination.meta.breadcrumbTrail[1].params = to.params;
                destination.meta.breadcrumbTrail[1].title = to.params.email;
                const promises = [
                  store.dispatch('applications/getAllWithEnvironments'),
                  store.dispatch('users/getWithAllEnvironments', to.params.email),
                ];
                Promise.all(promises).then(() => {
                  next();
                });
              },
              meta: {
                title: 'Data Profiles',
                auth: true,
                breadcrumbTrail: [
                  {
                    name: 'Users',
                  },
                  {
                    name: 'usersRead',
                    title: 'User',
                  },
                ],
              },
            },
            {
              path: 'dataprofiles/:application/:environment',
              component: () => import('./views/User/DataProfiles/Environment'),
              props: true,
              name: 'ApplicationEnvironmentDataProfiles',
              beforeEnter: (to, _from, next) => {
                const destination = to;
                destination.meta.breadcrumbTrail[1].params = to.params;
                destination.meta.breadcrumbTrail[1].title = to.params.email;
                const promises = [
                  store.dispatch('applications/getAllWithEnvironments'),
                  store.dispatch('users/getDataProfiles', {
                    email: to.params.email,
                    application: to.params.application,
                    environment: to.params.environment,
                  }),
                  store.dispatch('users/getAppPermissions', to.params),
                  store.dispatch('users/getWithAllEnvironments', to.params.email),
                  store.dispatch('mdm/getEntityNames'),
                ];
                Promise.all(promises).then(() => {
                  next();
                });
                store.dispatch('mdm/getEntityNames');
              },
              meta: {
                title: 'Data Profiles',
                auth: true,
                breadcrumbTrail: [
                  {
                    name: 'Users',
                  },
                  {
                    name: 'usersRead',
                    title: 'User',
                  },
                ],
              },
            },
            {
              path: 'applications',
              redirect: { name: 'userAccess' },
            },
            {
              path: 'access',
              name: 'userAccess',
              component: () => import('./views/User/Applications'),
              props: true,
              beforeEnter: (to, _from, next) => {
                const destination = to;
                destination.meta.breadcrumbTrail[1].params = to.params;
                destination.meta.breadcrumbTrail[1].title = to.params.email;
                const promises = [
                  store.dispatch('users/getWithAllEnvironments', to.params.email),
                  store.dispatch('applications/getAllWithEnvironments'),
                ];
                Promise.all(promises).then((responses) => {
                  const user = responses[0];
                  store.commit('users/setUser', user);
                  const applications = responses[1];
                  for (let i = 0; i < user.applications.length; i += 1) {
                    const userApp = user.applications[i];
                    const app = applications.find(x => x.id === userApp.id);
                    for (let x = 0; x < app.environments.length; x += 1) {
                      const env = app.environments[x];
                      let userEnv = userApp.environments.find(z => (
                        z.name.toLowerCase() === env.name.toLowerCase()
                      ));
                      if (!userEnv) {
                        userApp.environments.push({ name: env.name, roles: [] });
                        userEnv = userApp.environments.find(z => z.name === env.id);
                      }
                    }
                  }
                  store.dispatch('users/updateUserWithoutSave', user);
                  next();
                });
              },
              meta: {
                title: 'Edit',
                auth: true,
                breadcrumbTrail: [
                  {
                    name: 'Users',
                  },
                  {
                    name: 'usersRead',
                    title: 'User',
                  },
                  {
                    name: 'userAccess',
                    title: 'Access',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      path: '/user-search',
      component: () => import('./views/UserSearch'),
      meta: {
        title: 'User Search',
        auth: {
          permission: 'search_user',
        },
        allowAppManagers: true,
        fullWidth: true,
      },
      beforeEnter: (_to, _from, next) => {
        store.dispatch('mdm/getEntityNames');
        store.dispatch('applications/getAll');
        next();
      },
    },
    {
      path: '/',
      name: 'home',
      redirect: {
        name: 'Applications',
      },
      meta: {
        auth: true,
        allowAppManagers: true,
      },
    },
    {
      path: '/callback',
      name: 'Callback',
      meta: {
        auth: false,
        allowAnonymous: true,
      },
      component: () => import('./views/Auth/Callback'),
    },
    {
      path: '/reporting',
      name: 'Reporting',
      component: () => import('@/views/Reporting/Index'),
      meta: {
        auth: {
          permission: 'read_reporting',
        },
        showBreadcrumb: false,
      },
    },
    {
      path: '/accessdenied',
      name: 'Access Denied',
      component: () => import('./views/AccessDenied'),
      meta: {
        allowNonOnboarded: true,
      },
    },
    {
      path: '/:pathMatch(.*)',
      component: () => import('./views/NotFoundPage'),
    },
  ],
});

const hasPermission = perm => store.getters.permissions.map(x => x.name).indexOf(perm) > -1;
const hasPermissionForAnyApp = perm => (
  store.getters.permissionsForAllApps.map(x => x.name).indexOf(perm) > -1
);

const handlePageSecurity = (to, next) => {
  const timestamp = Math.round((new Date()).getTime());
  const expires = store.getters.expires;
  store.commit('progress/setProcessing', 'Authenticating');
  // First check if user is authenticated and their token hasn't expired
  if (
    store.getters.refreshToken
    && store.getters.isAuthenticated
    && timestamp < expires
  ) {
    // If they're an onboarded user, continue through the additional security checks
    if (store.getters.isOnboardedUser) {
      if (to.meta && to.meta.auth) {
        if (to.meta.auth === true) {
          store.commit('progress/removeProcessing', 'Authenticating');
          next();
        } else if (
          (
            to.meta.allowAppManagers
            && hasPermissionForAnyApp(to.meta.auth.permission)
          ) || hasPermission(to.meta.auth.permission)
        ) {
          store.commit('progress/removeProcessing', 'Authenticating');
          next();
        // If they don't have the correct permission,
        // we redirect them to the designated redirect route
        } else {
          store.commit('progress/removeProcessing', 'Authenticating');
          next(false);
          const redirectRouteName = to.meta.auth.redirect || 'Access Denied';
          const redirectRoute = router.resolve({ name: redirectRouteName }).route;
          const redirect = () => {
            router.push(redirectRoute);
          };
          handlePageSecurity(redirectRoute, redirect);
        }
      // If the route doesn't have an auth field,
      // then there's no specific permissions required so we let them through
      } else {
        store.commit('progress/removeProcessing', 'Authenticating');
        next();
      }
    // Allow non-onboarded users to go to certain pages
    } else if (to.meta && to.meta.allowNonOnboarded) {
      store.commit('progress/removeProcessing', 'Authenticating');
      next();
    // If they're authenticated, but not onboarded to the site, send them to the home page
    } else {
      store.commit('progress/removeProcessing', 'Authenticating');
      router.push({ name: 'Access Denied' });
    }
  // If they're not authenticated or their token has expired,
  // but they're going to a page that allows anonymous access, let them through
  // The main use for this is the auth callback page
  } else if (to.meta && to.meta.allowAnonymous) {
    store.commit('progress/removeProcessing', 'Authenticating');
    next();
  // If they're not authenticated or their token has expired,
  // and the page doesn't allow anonymous access, go through the auth flow
  } else if (store.getters.isAuthenticated && store.getters.refreshToken) {
    store.dispatch('refreshToken').then((token) => {
      if (!token) {
        store.commit('setRequestedRoute', to.path);
        store.commit('setAuthenticated', false);
        auth.authenticate(process.env.VUE_APP_AWS_COGNITO_IDP);
      }
      store.commit('progress/removeProcessing', 'Authenticating');
      next();
    });
  } else {
    store.commit('progress/removeProcessing', 'Authenticating');
    store.commit('setRequestedRoute', to.path);
    store.commit('setAuthenticated', false);
    auth.authenticate(process.env.VUE_APP_AWS_COGNITO_IDP);
  }
};

const updateUserRoles = (route) => {
  if (route && route.meta && !route.meta.allowAnonymous) {
    // const email = store.getters.emailAddress;
    const promises = [
      store.dispatch('getUser', false),
    ];
    return Promise.all(promises).then(() => true, () => true);
  }
  return new Promise(resolve => resolve(false));
};


router.beforeEach((to, _from, next) => {
  const timestamp = Math.round((new Date()).getTime());
  if (timestamp < store.getters.expires) {
    updateUserRoles(to).then(() => {
      handlePageSecurity(to, next);
    });
  } else if (store.state.auth.refreshToken) {
    store.dispatch('refreshToken').then((token) => {
      if (!token) {
        store.commit('setRequestedRoute', to.path);
        store.commit('setAuthenticated', false);
        auth.authenticate(process.env.VUE_APP_AWS_COGNITO_IDP);
      }
      updateUserRoles(to).then(() => {
        handlePageSecurity(to, next);
      });
    });
  } else if (window.location.pathname !== '/callback') {
    store.commit('setRequestedRoute', window.location.pathname);
    store.commit('setAuthenticated', false);
    auth.authenticate(process.env.VUE_APP_AWS_COGNITO_IDP);
  } else {
    updateUserRoles(to).then(() => {
      handlePageSecurity(to, next);
    });
  }
});

export default router;
